import React, { useState } from 'react';
import DobView from './view';

import { decryptText } from '@library/enc-dec';
import * as LoginServices from '@services/Login';

function Dob(props) {
	const [showError, setShowError] = useState('');
	const [dobG, setDobG] = useState({
		day: '',
		month: '',
		year: '',
		gender: '',
	});

	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	const handleOnChange = (from, event) => {
		try {
			setShowError('');
			let tempDobG = dobG;
			let val = event.target.value;
			let currentEle = null;
			
			if (from === 'day') {
				tempDobG.day = val;
				currentEle = document.getElementById('dayInput');
			} else if (from === 'month') {
				tempDobG.month = val;
				currentEle = document.getElementById('monthInput');
				let monthLabel = document.getElementById('monthLabel');
				if (monthLabel) {
					monthLabel.style.display = val ? 'block' : 'none';
				}
			} else if (from === 'year') {
				tempDobG.year = val;
				currentEle = document.getElementById('yearInput');
			} else if (from === 'gender') {
				tempDobG.gender = val;
				currentEle = document.getElementById('genderInput');
				let genderLabel = document.getElementById('genderLabel');
				if (genderLabel) {
					genderLabel.style.display = val ? 'block' : 'none';
				}
			}
			setDobG(tempDobG);

			if (currentEle) {
				currentEle.style.borderColor = val ? '#1A73E8' : '#8C8C8C';
			}
		} catch (err) {
			console.log('Error coming from handleOnChange()', err);
		}
	};

	const handleDOB = async () => {
		try {
			// debugger
			const { gender, day, month, year } = dobG;

			if (day && month && year && gender) {
				let formatedYear = parseInt(dobG.year);
				let currentYear = new Date().getFullYear();
				if (currentYear - formatedYear < 15 || currentYear - formatedYear > 100) {
					setShowError('Put a correct year');
					return;
				}
				
				// Creating ISO date string v1
				// const monthIndex = months.indexOf(month) + 1;
				// const dateObject = new Date(`${year}-${monthIndex}-${day}`);
				// dateObject.setUTCHours(0, 0, 0, 0);
				// const isoDateString = dateObject.toISOString();


				// Creating ISO date string v2
				const dateObject = new Date(Date.UTC(year, months.indexOf(month)	, day));
            	const isoDateString = dateObject.toISOString().split('T')[0] + 'T00:00:00.000Z';

				
				let authToken = decryptText(localStorage.getItem('eMediat'));
				const response = await LoginServices.updateUserInitialDetails(
					{ gender, dob: isoDateString },
					authToken
				);

				if (response.success) {
					setShowError('');
					props.handleViewAfterLogin('dob', response.data);
					return;
				}
				setShowError(response.messsage);
				return;
			}
			setShowError('Input Required');
			return;
		} catch (err) {
			console.log('Error coming from handleDOB()', err);
		}
	};

	return (
		<DobView
			showError={showError}
			dobG={dobG}
			months={months}
			handleOnChange={handleOnChange}
			handleDOB={handleDOB}
		/>
	);
}

export default Dob;
