import React, { useEffect, useState, useRef } from 'react';

import TosterView from './view';

function OTP(props) {
	const [bottom, setBottom] = useState("30px");
	useEffect(()=>{
		if(window.innerWidth < 576){
			setBottom("100px")
		}
	},[])
	return (
		<>
			<TosterView
				bottom={bottom}
				{...props}
			/>
		</>
	);
}

export default OTP;
