import React, { useEffect, useState } from "react";
import DeliveryAddressView from "./view";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

import { produce } from "immer";

import * as UserDashboardServices from "@services/UserDashboard";
import * as UserDashboardActions from "@redux/actions/UserDashboard";
import * as TimelineRedirectActions from '@redux/actions/TimelineRedirect';

import { decryptText } from "@library/enc-dec";

function DeliveryAddress(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allCountries = require("@assets/countryCode.json");

  const [iserror, setIserror] = useState(false);

  const [allStates, setAllStates] = useState([]);
  const user = useSelector((state) => state.dashboard.user);
  const timelineRedirectDetails = useSelector((state) => state.timelineRedirect.timelineRedirectDetails);
  const [savingLoading, setSavingLoading] = useState(false);

  useEffect(() => {
    if (user && user.country) {
      let selectedCountryObj = allCountries.filter(function (country) {
        return country.countryName === user.country;
      });
      if (selectedCountryObj.length > 0 && selectedCountryObj[0].regions) {
        setAllStates(selectedCountryObj[0].regions);
      }
    }
  }, [user])

  const handleUserUpdate = (key, value) => {
    try {
      setIserror(false);
      let updatedUser = produce(user, (draft) => {
        draft[key] = value;
      });

      dispatch(UserDashboardActions.updateUserDetails(updatedUser));
    } catch (err) {
      console.log(
        "Delivery Address : Error coming from handleTextInput()",
        err
      );
    }
  };

  const handleInputChange = (event, inputAction) => {
    try {
      let eventType = event.target.type;
      let inputValue = event.target.value;
      if (eventType === "checkbox") {
        inputValue = event.target.checked;

        handleUserUpdate("addressAcknowledged", inputValue);
      } else {
        handleUserUpdate(inputAction, inputValue);
      }
    } catch (err) {
      console.log(
        "Delivery Address: Error coming from handleInputChange()",
        err
      );
    }
  };

  const handleCountryChange = (event) => {
    try {
      let selectedCountry = event.target.value;
      let selectedCountryObj = allCountries.filter(function (country) {
        return country.countryName === selectedCountry;
      });
      setAllStates(selectedCountryObj[0].regions);
      handleInputChange(event, "country");
    } catch (err) {
      console.log(
        "Delivery Address : Error coming from handleCountryChange()",
        err
      );
    }
  };

  const handleSave = async () => {
    try {
      const { flatHouseNumber, buildingName, colonyName, areaName } = user;
      const { landmark, country, countryCode } = user;
      const { pincode, state, city, addressAcknowledged } = user;

      const payload = {
        flatHouseNumber,
        buildingName,
        colonyName,
        areaName,
        landmark,
        country,
        pincode,
        state,
        city,
        addressAcknowledged,
      };

      if (
        !flatHouseNumber ||
        !colonyName ||
        !areaName ||
        !landmark ||
        !pincode ||
        !country ||
        !state ||
        !city
      ) {
        setIserror(true);
        console.log(
          "error",
          flatHouseNumber,
          colonyName,
          areaName,
          landmark,
          pincode,
          country,
          state,
          city
        );
        return;
      }

      setSavingLoading(true)
      let authToken = decryptText(localStorage.getItem("eMediat"));
      const response = await UserDashboardServices.updateDeliveryAddress(
        payload,
        authToken
      );

      if (response.success) {
        alert(response.message);
        setIserror(false);
        dispatch(
          UserDashboardActions.updateUserDetails({
            ...response.data,
            bssFormDetails: user.bssFormDetails,
          })
        );
        setSavingLoading(false)
        if (timelineRedirectDetails && timelineRedirectDetails.purchaseId && timelineRedirectDetails.purchaseId !== "" && timelineRedirectDetails.section === "deliveryAddress") {
          navigate(`/timeline/${timelineRedirectDetails.purchaseId}`);
        }
        else {
          dispatch(TimelineRedirectActions.resetTimelineRedirectReducers())
        }

        return;
      }
      setSavingLoading(false)
      alert(response.message);
    } catch (err) {
      console.log("Delivery Address: Error coming from handleSave()", err);
    }
  };

  return (
    <>
      {user && (
        <DeliveryAddressView
          iserror={iserror}
          userData={user}
          handleInputChange={handleInputChange}
          allCountries={allCountries}
          allStates={allStates}
          handleCountryChange={handleCountryChange}
          handleSave={handleSave}
          savingLoading={savingLoading}
        />
      )}
    </>
  );
}

export default DeliveryAddress;
