import React from "react";
import "./Footer.css";
import footerLogo from "@assets/footer/footerLogo.png";
import { Link } from "react-router-dom";

import englishIcon from "@assets/footer/englishIcon.png";

import SupportIcon from "@assets/footer/supportIcon.png";
import BusinessIcon from "@assets/footer/businessIcon.png";

import LinkInIcon from "@assets/footer/linkInIcon.png";
import InstagramIcon from "@assets/footer/instagramIcon.png";
import FacebookIcon from "@assets/footer/facebookIcon.png";
import WhatsappIcon from "@assets/footer/whatsappIcon.png";
import TwitterIcon from "@assets/footer/twitterIcon.png";
import YouTubeIcon from "@assets/footer/youTubeIcon.png";
import TelegramIcon from "@assets/footer/telegramIcon.png";
import LocationIcon from "@assets/footer/locationIcon.png";

export default function Footer() {
  return (
    <div id="footerContainer">
      <footer id="footer">
        {/* Mobile footer */}
        <div className="container d-block d-md-none">
          <div className="row">
            <div
              className="col-12 d-flex justify-content-between"
              style={{ alignItems: "center" }}
            >
              <img
                alt="footerLogo"
                src={footerLogo}
                style={{ width: "150px" }}
              />
              <img
                alt="englishIcon"
                src={englishIcon}
                style={{
                  width: "100px",
                  height: "24px",
                  paddingRight: "10px",
                }}
              />
            </div>
          </div>

          <div
            className="row"
            style={{ margin: "30px 0px", justifyContent: "center" }}
          >
            <img
              src={LinkInIcon}
              alt="LinkInIcon"
              className="socialMediaIconsTM"
            />
            <img
              src={InstagramIcon}
              alt="InstagramIcon"
              className="socialMediaIconsTM"
            />
            <img
              src={FacebookIcon}
              alt="FacebookIcon"
              className="socialMediaIconsTM"
            />
            <img
              src={WhatsappIcon}
              alt="WhatsappIcon"
              className="socialMediaIconsTM"
            />
            <img
              src={TwitterIcon}
              alt="TwitterIcon"
              className="socialMediaIconsTM"
            />
            <img
              src={YouTubeIcon}
              alt="YouTubeIcon"
              className="socialMediaIconsTM"
            />
            <img
              src={TelegramIcon}
              alt="TelegramIcon"
              className="socialMediaIconsTM"
            />
          </div>

          <div className="row" id="mobileFooter">
            <p>
              <div className="row" style={{ justifyContent: "center" }}>
                <img
                  alt="SupportIcon"
                  src={SupportIcon}
                  className="footerLogoTM"
                />
                support@emediskill.com
              </div>
            </p>

            <p>
              <div
                className="row"
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <img
                  alt="BusinessIcon"
                  src={BusinessIcon}
                  className="footerLogoTM"
                />
                business@emediskill.com
              </div>
            </p>

            {/* <p>
              <div
                className="row"
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <img
                  alt="LocationIcon"
                  src={LocationIcon}
                  className="footerLogoTM"
                />
                Aristomisr, F-06, Plot No. 42, Sr. No 588/2A, Burhani Colony,
                Market Yard, Pune - 411037
              </div>
            </p> */}

            <div>
              <p className="footerPTage">Get the app</p>
              <Link to="/aboutus" className="undoAnchorEffect">
                <p className="footerPTage">About us</p>
              </Link>
            </div>

            <div>
              <Link
                to="/articles/terms-of-service"
                className="undoAnchorEffect"
                onClick={() => {
                  window.location.assign(
                    "https://v2.emediskill.com/articles/terms-of-service"
                  );
                }}
              >
                <p className="footerPTage">Terms of Service</p>
              </Link>
              <Link
                to="/articles/privacy-policy"
                className="undoAnchorEffect"
                onClick={() => {
                  window.location.assign(
                    "https://v2.emediskill.com/articles/privacy-policy"
                  );
                }}
              >
                <p className="footerPTage">Privacy policy</p>
              </Link>
            </div>
          </div>
        </div>
        {/* End of mobile footer */}

        {/* Desktop footer */}
        <div className="container d-none d-md-block" id="desktopFooter">
          <div className="row" style={{ marginBottom: "40px" }}>
            <div className="col-sm-3">
              <img
                alt="footerLogo"
                src={footerLogo}
                style={{ width: "147px" }}
              />
            </div>

            <div className="col-6 text-center">
              <Link
                target="_blank"
                to="https://www.linkedin.com/in/emediskill-services-579773282/"
              >
                <img
                  src={LinkInIcon}
                  alt="LinkInIcon"
                  className="socialMediaIcons"
                />
              </Link>

              <Link
                to="https://www.instagram.com/emediskill/?next=%2F&hl=en"
                target="_blank"
              >
                <img
                  src={InstagramIcon}
                  alt="InstagramIcon"
                  className="socialMediaIcons"
                />
              </Link>

              <Link
                to="https://www.facebook.com/profile.php?id=100076043086766"
                target="_blank"
              >
                <img
                  src={FacebookIcon}
                  alt="FacebookIcon"
                  className="socialMediaIcons"
                />
              </Link>
              <img
                src={WhatsappIcon}
                alt="WhatsappIcon"
                className="socialMediaIcons"
              />
              <img
                src={TwitterIcon}
                alt="TwitterIcon"
                className="socialMediaIcons"
              />
              <img
                src={YouTubeIcon}
                alt="YouTubeIcon"
                className="socialMediaIcons"
              />
              <img
                src={TelegramIcon}
                alt="TelegramIcon"
                className="socialMediaIcons"
              />
            </div>

            <div className="col-3" style={{ textAlign: "right" }}>
              <img
                alt="englishIcon"
                src={englishIcon}
                style={{ width: "137px" }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-4">
              <p>
                <div className="row">
                  <div className="col-1" style={{ padding: "0px" }}>
                    <img
                      alt="SupportIcon"
                      src={SupportIcon}
                      className="footerLogo"
                    />
                  </div>
                  <div className="col-11">support@emediskill.com</div>
                </div>
              </p>

              <p>
                <div className="row">
                  <div className="col-1" style={{ padding: "0px" }}>
                    <img
                      alt="BusinessIcon"
                      src={BusinessIcon}
                      className="footerLogo"
                    />
                  </div>
                  <div className="col-11" style={{ wordWrap: "break-word" }}>
                    business@emediskill.com
                  </div>
                </div>
              </p>
              {/* <p>
                <div className="row">
                  <div className="col-1" style={{ padding: "0px" }}>
                    <img
                      alt="LocationIcon"
                      src={LocationIcon}
                      className="footerLogo"
                    />
                  </div>
                  <div className="col-11" style={{ wordWrap: "break-word" }}>
                    Aristomisr, F-06, Plot No. 42, Sr. No 588/2A, Burhani
                    Colony, Market Yard, Pune - 411037
                  </div>
                </div>
              </p> */}
            </div>

            <div className="col-3">
              <Link
                to="/articles/terms-of-service"
                className="undoAnchorEffect"
                onClick={() => {
                  window.location.assign(
                    "https://v2.emediskill.com/articles/terms-of-service"
                  );
                }}
              >
                <p className="footerPTage">Terms of Service</p>
              </Link>
              <Link
                to="/articles/privacy-policy"
                className="undoAnchorEffect"
                onClick={() => {
                  window.location.assign(
                    "https://v2.emediskill.com/articles/privacy-policy"
                  );
                }}
              >
                <p className="footerPTage">Privacy policy</p>
              </Link>
            </div>

            <div className="col-3">
              <p className="footerPTage">Get the App</p>
              <Link to="/aboutus" className="undoAnchorEffect">
                <p className="footerPTage">About us</p>
              </Link>
            </div>

            <div className="col-2" style={{ textAlign: "right" }}>
              <Link to="/request-a-call" className="undoAnchorEffect">
                <p className="footerPTage">Contact us</p>
              </Link>
            </div>
          </div>
        </div>
        {/* End of desktop footer */}
      </footer>
      <div id="emptySection">
        <span>
          ©{" "}
          <Link
            to="/articles/ownership"
            className="undoAnchorEffect"
            onClick={() => {
              window.location.assign(
                "https://www.emediskill.com/articles/ownership"
              );
            }}
          >
            {"eMediSkill" + " "}
          </Link>
          Institute Of Science And Technology Private Limited, a sub brand of
          <Link
            to="/articles/ownership"
            className="undoAnchorEffect"
            onClick={() => {
              window.location.assign(
                "https://www.emediskill.com/articles/ownership"
              );
            }}
          >
            {" Intelegent Services FZC" + ", "}
          </Link>
          {new Date().getFullYear()}, All rights are reserved
        </span>
      </div>
    </div>
  );
}
