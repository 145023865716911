import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ExploreView from './view';
import { useDispatch, useSelector } from 'react-redux';
import * as CourseServices from '@services/Course';

import {
	getPurchasesCount,
	updateCurrencyValue,
	handlePaidCourse,
} from '@library/helpers';

import { encryptText, decryptText } from '@library/enc-dec';

import * as LoginServices from '@services/Login';
import * as LeadsServices from '@services/Leads';
import * as ExploreServices from '@services/Explore';
import * as CartServices from '@services/Cart';
import * as DashboardServices from '@services/UserDashboard/MyLearning';
import * as OfferServices from "@services/Offers";

import * as ExploreActions from '@redux/actions/Explore';
import * as CartActions from '@redux/actions/Cart';
import * as NavbarActions from '@redux/actions/Navbar';
import * as OTPActions from '@redux/actions/OTP';
import * as TopLoadingBarActions from '@redux/actions/TopLoadingBar';
import * as DashboardActions from '@redux/actions/UserDashboard/MyLearning';
import * as UserSpecificOfferAction from '@redux/actions/UserSpecificOffer';

import Navbar from '@components/Navbar';
import Footer from '@components/Footer';
import TopLoadingBar from '@components/TopLoadingBar';

function Explore() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);

	const typeOfCourses = useSelector((state) => state.explore.typeOfCourses);
	const currentCourseDetails = useSelector(
		(state) => state.explore.currentCourseDetails
	);
	const loginState = useSelector((state) => state.navbar.loginState);
	const cartItems = useSelector((state) => state.cart.cartItems);
	const purchasedCourses = useSelector(
		(state) => state.dashboard.purchasedCourses
	);

	// START : states to handle phoneNo and opt popup
	const nameAndPhoneNoOtpOpenBtnRef = useRef(null);
	const [nameAndPhoneNoOtpStage, setNameAndPhoneNoOtpStage] = useState('');
	const [userLogedIn, setUserLogedIn] = useState(false);

	const [fullName, setFullName] = useState('');
	const [phoneNo, setPhoneNo] = useState('');
	const [countryCode, setCountryCode] = useState('');

	const [enrollContactNumber, setEnrollContactNumber] = useState('');
	const [enrollCountryCode, setEnrollCountryCode] = useState('');

	const [OTPToken, setOTPToken] = useState(null);
	const [buttonType, setButtontype] = useState('');

	const [addToCartIconIsLoading, setAddToCartIconIsLoading] = useState('');
	const userSpecificOfferDetails = useSelector((state) => state.userSpecificOffer.userSpecificOfferDetails);
	const globalCurrencySymbol = decryptText(localStorage.getItem('cs'));
	const [addToCartCourseId, setAddToCartCourseId] = useState("")

	useEffect(() => {
		window.scroll(0, 0);
		dispatch(TopLoadingBarActions.updateLoadingBarProgress(40));
		helperToGetUserSpecificOffer();
		getPurchasesCoursesDetails();
		// handleGetCartItems();
	}, []);

	useEffect(() => {
		setUserLogedIn(loginState);
	}, [loginState]);


	const helperToGetUserSpecificOffer = async () => {
		const userSpecificOffer = await getUserSpecificOffer();
		if (userSpecificOffer.length > 0) {
			dispatch(
				UserSpecificOfferAction.updateUserSpecificOfferDetails(userSpecificOffer)
			)
		}
		getExploreCourses(userSpecificOffer);
	}

	const getUserSpecificOffer = async () => {
		let userSpecificOffer = [];
		try {
			if (
				localStorage.getItem("eMediat")
			) {
				let authToken = decryptText(localStorage.getItem("eMediat"));
				const response = await OfferServices.getUserSpecificOffer(
					authToken
				);		
				if (response.success) {
					if (
						response.data &&
						response.data.userSpecificOffer &&
						response.data.userSpecificOffer.length > 0
					) {
						userSpecificOffer = response.data.userSpecificOffer;
					}
				} else {
					if (response.message && response.message !== "User specific offer not found") {
						console.log("Issue coming from getUserSpecificOffer()", response);
					}
				}
			}
		} catch (err) {
			console.log("Error coming from getUserSpecificOffer()", err);
		}
		return userSpecificOffer;
	};

	const getPurchasesCoursesDetails = async () => {
		try {
			let authToken = decryptText(localStorage.getItem('eMediat'));
			const response = await DashboardServices.getPurchasedCourses(authToken);
			if (response.success) {
				dispatch(DashboardActions.updatePurchasedCourses(response.data.courses));
				dispatch(DashboardActions.updateSeriesList(response.data.series));
			}
		} catch (err) {
			console.log('Error coming from getCoursesDetails()', err);
		}
	};

	const getExploreCourses = async (userSpecificOffer) => {
		try {
			const continent = decryptText(localStorage.getItem('cont'));
			const currencyValue = decryptText(localStorage.getItem('cv'));
			await updateCurrencyValue();
			const response = await ExploreServices.getCoursesByType({
				continent: continent,
				currencyValue: 1 * currencyValue,
			});
			if (response.success) {
				const updatedAllCourses = await updateAllCourses(response.data, userSpecificOffer);
				dispatch(ExploreActions.updateTypeOfCourses(updatedAllCourses));

				// dispatch(ExploreActions.updateTypeOfCourses(response.data));
				dispatch(TopLoadingBarActions.updateLoadingBarProgress(100));
				setIsLoading(false);
				return;
			}
			alert(response.message);
		} catch (err) {
			console.log('Error coming from getExploreCourses()', err);
		}
	};


	const updateAllCourses = async (allCategoryData, userSpecificOffer) => {
		for (const [categoryName, categoryData] of Object.entries(allCategoryData)) {
			if (categoryData.courses && categoryData.courses.length > 0) {
				let preFetchLocalDbCoursesList = []
				let jsonCheckDetails = {
					returnType: [],
				};
				const encryptedData = localStorage.getItem("crsl");
				if (encryptedData) {
					const decryptedData = decryptText(encryptedData, jsonCheckDetails);
					if (decryptedData && typeof decryptedData === "string") {
						try {
							preFetchLocalDbCoursesList = JSON.parse(decryptedData);
						} catch (error) {
							localStorage.removeItem("crsl");
							window.location.reload();
						}
					}
				}

				let useThisUserSpecificOfferDetails = []
				if(userSpecificOfferDetails.length > 0){
					useThisUserSpecificOfferDetails = userSpecificOfferDetails
				}
				else{
					useThisUserSpecificOfferDetails = userSpecificOffer
				}
				await Promise.all(categoryData.courses.map(async (course, index) => {
					const updatedCourseData = await handlePaidCourse(useThisUserSpecificOfferDetails, course, preFetchLocalDbCoursesList);
					allCategoryData[categoryName]['courses'][index]['offerPrice'] = updatedCourseData['offerPriceInNumbers']
					allCategoryData[categoryName]['courses'][index]['crossedPrice'] = updatedCourseData['crossedPriceInNumbers']
					allCategoryData[categoryName]['courses'][index]['offerStartAt'] = updatedCourseData['offerStartAt']
					allCategoryData[categoryName]['courses'][index]['offerEndAt'] = updatedCourseData['offerEndAt']
					allCategoryData[categoryName]['courses'][index]['isInOffer'] = updatedCourseData['isInOffer']
				}))
			}
		}
		return allCategoryData;
	}


	const handleRedirectToCoursePage = (seoUrlSlug) => {
		navigate('/course/' + seoUrlSlug);
	};

	const handleRelatedCoursesScroll = (index, direction) => {
		try {
			let eleId = 'relatedCourses_' + index;
			let ele = document.getElementById(eleId);
			if (ele) {
				if (direction === 'left') {
					ele.scrollTo({
						left: ele.scrollLeft - 200,
						behavior: 'smooth',
					});
				} else if (direction === 'right') {
					ele.scrollTo({
						left: ele.scrollLeft + 200,
						behavior: 'smooth',
					});
				}
			}
		} catch (err) {
			console.log('Error coming from handleRelatedCoursesScroll()', err);
		}
	};

	const handleGetCartItems = async () => {
		try {
			let authToken = decryptText(localStorage.getItem('eMediat'));
			let countryName = decryptText(localStorage.getItem('c'));
			let currencySymbol = decryptText(localStorage.getItem('cs'));

			let payload = {
				countryName: countryName,
				currencySymbol: currencySymbol,
			};

			let response = await CartServices.getCartItems(payload, authToken);

			if (response.success) {
				dispatch(CartActions.updateCartTotalValueInNumbers());

				dispatch(
					CartActions.updateCartDetails({
						cartItems: response.data?.itemsList,
						cartItemCount: response.data?.totalItems,
						cartTotalValue: response.data?.totalValue,
						cartTotalValueInNumbers: response.data?.totalValueInNumbers,
						discountPercentage: 0,
						discountValue: '0',
						discountValueInNumbers: 0,
						offerDetails: null,
					})
				);

				dispatch(CartActions.updateInstallmentOption(null));
				dispatch(CartActions.updateSelectedPaymentGateway(null));
			}
		} catch (err) {
			console.log('Error coming from handleGetCartItems()', err);
		}
	};

	const handleAddToCart = async (courseDetails, isLoggedIn = false) => {
		try {
			setAddToCartCourseId(courseDetails._id)
			dispatch(ExploreActions.updateCurrentCourseDetails(courseDetails));
			// debugger
			if (!userLogedIn && !isLoggedIn) {
				openOtpPopUp('', '', 'init');
				return;
			}

			setAddToCartIconIsLoading(courseDetails._id)

			let authToken = decryptText(localStorage.getItem('eMediat'));

			let continent = decryptText(localStorage.getItem('cont'));
			let countryName = decryptText(localStorage.getItem('c'));
			let currencySymbol = decryptText(localStorage.getItem('cs'));
			let currencyValue = decryptText(localStorage.getItem('cv'));
			await updateCurrencyValue();

			const updatedCourseDetails = await handlePaidCourse(userSpecificOfferDetails, courseDetails);
			const payload = {
				courseId: updatedCourseDetails?._id,
				isInOffer: updatedCourseDetails?.isInOffer,
				continent: continent,
				countryName: countryName,
				currencySymbol: currencySymbol,
				currencyValue: currencyValue,
				offerStartAt: updatedCourseDetails?.offerStartAt,
				offerEndAt: updatedCourseDetails?.offerEndAt,
			};
			const response = await CartServices.addToCart(payload, authToken);

			if (response.success) {
				// Getting latest cart Items
				handleGetCartItems();
			}
			setAddToCartIconIsLoading('')
		} catch (err) {
			setAddToCartIconIsLoading('')
			console.log('Error coming from handleAddToCart()', err);
		}
	};



	// Handling the Phone and OTP below
	const flushNameAndPhoneNoOtpStage = () => {
		setNameAndPhoneNoOtpStage('');
	};

	const openOtpPopUp = (countryCode, phoneNo, stage) => {
		try {
			if (stage === 'otpStage') {
				setCountryCode(countryCode);
				setPhoneNo(phoneNo);
			}
			setNameAndPhoneNoOtpStage(stage);
			clickRefBtn(nameAndPhoneNoOtpOpenBtnRef);
		} catch (err) {
			console.log('Error coming from openOtpPopUp()', err);
		}
	};

	const clickRefBtn = (r) => {
		if (r.current) {
			r.current.click();
		}
	};

	const fullNameSetter = (name) => {
		setFullName(name);
	};

	const handleLeadsVerifyOTP = async (OTP) => {
		try {
			// Full name is the display name of the user
			let payload = {};
			if (buttonType === 'interestButton') {
				payload = {
					otp: OTP,
					contactNumber: phoneNo,
					countryCode: countryCode,
					displyaName: fullName,
					courseId: currentCourseDetails._id,
					source: "interest"
				};
			} else {
				payload = {
					otp: OTP,
					contactNumber: enrollContactNumber,
					countryCode: enrollCountryCode,
					courseId: currentCourseDetails._id,
					source: "onlyVerifyOtp" // onlyVerifyOtp coz from here only add to cart will happen
				};
			}

			const response = await LeadsServices.verifyLeadsOTP(payload, OTPToken);
			if (!response.success) {
				setUserLogedIn(false);
				dispatch(OTPActions.updateOTPErrorMsg(response.message));
				return response;
			}
			// Here mark user as logged in and get the cart details of the user
			saveToken(response.data?.authToken);
			setUserLogedIn(true);

			setTimeout(async () => {
				isCoursePurchased()
				const purchasesCount = await getPurchasesCount();
				dispatch(NavbarActions.updatePurchasesCount(purchasesCount));
				dispatch(NavbarActions.updateLoginState(true));
			}, 2000);


			handleAddToCart(currentCourseDetails, true)

			return response;
		} catch (err) {
			console.log('Error coming from leadsVerifyOTP()', err);
		}
	};


	const isCoursePurchased = async () => {
		let authToken = decryptText(localStorage.getItem('eMediat'));
		if(addToCartCourseId !== "" ){
			const response = await CourseServices.getCourseByIdAuth(
				addToCartCourseId,
				authToken
			);
			if (response.success) {
				if(response.data && response.data.isPurchasedCourse){
					window.location.reload();
				}
			}
			else {
				alert(response.message);
			}	
		}
	}


	const saveToken = (token) => {
		try {
			window.localStorage.setItem('eMediat', encryptText(token));
			window.localStorage.setItem('eMediatt', encryptText(Date.now()));
		} catch (err) {
			console.log('Error coming from  saveToken()', err);
		}
	};

	const handleResendOTP = async (userContactNumber) => {
		try {
			dispatch(OTPActions.updateOTPErrorMsg(''));
			for (var i = 1; i <= 5; i++) {
				dispatch(OTPActions.updateOTPNumber({ OTPNumber: '', colNumber: i }));
			}

			const response = await LoginServices.login(userContactNumber);

			if (!response.success) {
				alert(response.message);
				return;
			}
			setOTPToken(response.data.token);
		} catch (err) {
			console.log('Error coming from handleResendOTP()', err);
		}
	};

	const createLead = async (courseId, authToken) => {
		const leadResponse = await LeadsServices.addToLead(courseId, authToken);
		alert(leadResponse.message);
	};

	const handleEnrollSendOTP = async (countryCode, contactNumber, buttonType) => {
		try {
			setButtontype(buttonType);
			setEnrollCountryCode(countryCode);
			setEnrollContactNumber(contactNumber);
			const response = await LoginServices.login(contactNumber);
			if (!response.success) {
				alert(response.message);
				return;
			}
			setOTPToken(response.data.token);
		} catch (err) {
			console.log('Error coming from handleEnrollSendOTP()', err);
		}
	};

	return (
		<>
			<Navbar />
			<TopLoadingBar />
			<ExploreView
				exploreData={typeOfCourses}
				isLoading={isLoading}
				handleRelatedCoursesScroll={handleRelatedCoursesScroll}
				handleAddToCart={handleAddToCart}
				handleRedirectToCoursePage={handleRedirectToCoursePage}
				// START : states for phoneNo and opt popup
				nameAndPhoneNoOtpOpenBtnRef={nameAndPhoneNoOtpOpenBtnRef}
				openOtpPopUp={openOtpPopUp}
				nameAndPhoneNoOtpStage={nameAndPhoneNoOtpStage}
				countryCode={countryCode}
				phoneNo={phoneNo}
				fullNameSetter={fullNameSetter}
				flushNameAndPhoneNoOtpStage={flushNameAndPhoneNoOtpStage}
				handleResendOTP={() =>
					handleResendOTP(
						buttonType === 'interestButton' ? phoneNo : enrollContactNumber
					)
				}
				handleEnrollSendOTP={handleEnrollSendOTP}
				handleLeadsVerifyOTP={handleLeadsVerifyOTP}
				cartItems={cartItems}
				purchasedCourses={purchasedCourses}
				addToCartIconIsLoading={addToCartIconIsLoading}
				globalCurrencySymbol={globalCurrencySymbol}
			/>
			<Footer />
		</>
	);
}

export default Explore;
