import React, { useRef } from 'react';
import { useNavigate } from "react-router-dom";

import ConfirmOrderView from './view';
import { jsPDF } from "jspdf";
import LoaderGif from "@assets/course/loading.gif";
import DownloadIcon from "@assets/timeline/icons/download.png";
import InvoiceMobile from '@components/InvoiceMobile';
import HdfcInvoice from '@components/Invoice/HdfcInvoice';
import StripeInvoice from '@components/Invoice/StripeInvoice';
import { renderToStaticMarkup } from "react-dom/server";
import { RobotoRegular } from "@assets/Roboto-Regular-normal.js"

function ConfirmOrder(props) {
	const navigate = useNavigate();
	const modalRef = useRef(null)
	const closehomePageConfirmOrderViewModalBtn = useRef(null)

	const handleDownloadInvoiceFromHomePage = (event, confirmOrderDetails) =>{
		generatePdf(event, confirmOrderDetails)

		// old code
		// if(closehomePageConfirmOrderViewModalBtn.current){
		// 	closehomePageConfirmOrderViewModalBtn.current.click()
		// 	navigate('/invoice', { state: { invoice: confirmOrderDetails } });
		// }
	}

	const generatePdf = async (event, invoice) => {
		try {
			event.target.src = LoaderGif

			const doc = new jsPDF("p", "pt", "a4", true);

			doc.addFileToVFS("Roboto-Regular.ttf", RobotoRegular);
			doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
			doc.setFont("Roboto");
	  
			var InvoiceComponent = null
			if (invoice.data && invoice.data.paymentMethod && invoice.data.paymentMethod) {
				// testing : razorpay stripe
				if (invoice.data.paymentMethod === "stripe") {
				  InvoiceComponent = <StripeInvoice data={invoice.data} />
				} else if (invoice.data.paymentMethod === "ccavenue") {
				  InvoiceComponent = <HdfcInvoice data={invoice.data} />
				}
				else {
				  InvoiceComponent = <InvoiceMobile invoice={invoice} />
				}
			  }
			  else {
				alert("Issue in generating invoice go to dashboard and check again")
				event.target.src = DownloadIcon
				return
			  }

			// var InvoiceComponent = <InvoiceMobile invoice={invoice} />
			var htmlPDFPage = renderToStaticMarkup(InvoiceComponent);

			doc.internal.write(0, "Tw");
			const pdfPromise = new Promise((resolve) => {
				doc.html(htmlPDFPage, {
					callback: function () {
						resolve(doc);
					},
				});
			});

			// Wait for the promise to resolve before continuing
			const generatedDoc = await pdfPromise;
			generatedDoc.save("invoice.pdf");


			setTimeout(function () {
				event.target.src = DownloadIcon
			}, 100)

		} catch (err) {
			console.log("Error", err);
		}
	};



	const handleRedirectToDashboard = () => {
		navigate("/dashboard", {});
	}
	const emptyLocationSateValues = () =>{
		navigate("/", {
			replace: true,
			state: {},
		});
	}

	return (
		<>
			<ConfirmOrderView
				modalRef={modalRef}
				handleRedirectToDashboard={handleRedirectToDashboard}
				handleDownloadInvoiceFromHomePage={handleDownloadInvoiceFromHomePage}
				emptyLocationSateValues={emptyLocationSateValues}
				closehomePageConfirmOrderViewModalBtn={closehomePageConfirmOrderViewModalBtn}
				showInVoicePossible={props.showInVoicePossible}
				openhomePageConfirmOrderViewModalBtn={props.openhomePageConfirmOrderViewModalBtn}
			/>
		</>
	);
}

export default ConfirmOrder;
