export const getPurchaseByCourseId = async (authToken, courseId) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getPurchaseByCourseId/${courseId}`,
			{
				method: 'GET',
				headers: {
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Userdashboard Services getPurchaseByCourseId()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};


export const getTimelineDetails = async (purchaseId, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getTimelineDetails/${purchaseId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from DashboardService getTimelineDetails services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};



export const updateCertificateDetailsAsPurchaseId = async (purchaseId, numberOfCertificates, authToken) => {
	try {
		let endpoint = "updateCertificateDetailsAsPurchaseId";
		let payload = {
			purchaseId:purchaseId,
			numberOfCertificates:numberOfCertificates
		}		
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/${endpoint}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
				body: JSON.stringify(payload),				
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from timeline services : updateCertificateDetailsAsPurchaseId()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
