export const getCourseById = async (seoUrlSlug) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getCourseById/${seoUrlSlug}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course services : Get Course by Id',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getCourseByIdAuth = async (seoUrlSlug, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getCourseByIdAuth/${seoUrlSlug}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course services : Get Course by Id Auth',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getRelatedCourses = async (courseCategories) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getRelatedCourses`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				courseCategories: courseCategories,
			}),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course services : getRelatedCourses()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getAllCourses = async () => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getCourses`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Course services : getAllCourses()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
