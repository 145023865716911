import React, { useState, useEffect } from 'react';

import Navbar from '@components/Navbar';
import Footer from '@components/Footer';
import TopLoadingBar from '@components/TopLoadingBar';

import TimelineView from './view';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { decryptText } from '@library/enc-dec';

import * as DashboardServices from '@services/UserDashboard/Timeline';
import * as DashboardActions from '@redux/actions/UserDashboard/Timeline';
import * as UserDashboardActions from '@redux/actions/UserDashboard';
import * as TimelineRedirectActions from '@redux/actions/TimelineRedirect';
import * as TopLoadingBarActions from '@redux/actions/TopLoadingBar';

import apiClaimCertificationStatsData from '@pages/UserDashboard/MyLearning/Timeline/StatsDummyData/apiClaimCertificationData.json';
import { produce } from 'immer';

function Timeline() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [inView, setInView] = useState('');

	const [claimCertificationStatsData, setClaimCertificationStatsData] = useState({});

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		dispatch(TopLoadingBarActions.updateLoadingBarProgress(10));
		window.scroll(0,0)
		updateNavBarStyle();
		getTimeLineData()

		handleClaimCertificationStatsUpdate();
	}, []);


	const getTimeLineData = async () => {
		try {
			const purchaseId = window.location.href.split('/').pop();
			let authToken = decryptText(localStorage.getItem('eMediat'));
			dispatch(TopLoadingBarActions.updateLoadingBarProgress(50));
			let response = await DashboardServices.getTimelineDetails(purchaseId, authToken);
			if (response.success) {
				dispatch(DashboardActions.updateTimelineData(response.data[0]));

				let responseCourseUpdate = produce(response, (draft) => {
					draft.data[0].courseDetails.content = draft.data[0].content
				})
				dispatch(DashboardActions.updatePurchasedCourse(responseCourseUpdate.data[0].courseDetails));

				let responseCopy = produce(response, (draft) => {
					draft.data[0].userDetails.bssFormDetails = draft.data[0].bssFormDetails
				})
				dispatch(UserDashboardActions.updateUserDetails(responseCopy.data[0].userDetails));

				// setTimeout(function(){
				// 	setIsLoading(false)
				// },5000)

				setIsLoading(false)
				dispatch(TopLoadingBarActions.updateLoadingBarProgress(100));
				return
			}
			navigate('/');
		} catch (err) {
			console.log('Error coming from getPurchasedCourseDetails()', err);
		}
		dispatch(TopLoadingBarActions.updateLoadingBarProgress(100));
	}

	const handleInview = (com) => {
		try {
			if (com === inView) {
				setInView('');
			} else {
				setInView(com);
			}
		} catch (err) {
			console.log('Error coming from handleInview()', err);
		}
	};

	const updateNavBarStyle = () => {
		try {
			if (
				document.getElementsByTagName('nav') &&
				document.getElementsByTagName('nav')[0]
			) {
				let nav = document.getElementsByTagName('nav')[0];
				nav.style.boxShadow = 'unset';
				nav.style.borderBottom = '1px solid #E4E5E7';
			}
		} catch (err) {
			console.log('Error coming from updateNavBarStyle()', err);
		}
	};

	const handleClaimCertificationStatsUpdate = () => {
		try {
			setClaimCertificationStatsData(apiClaimCertificationStatsData);
		} catch (err) {
			console.log('Error coming from handleClaimCertificationStatsUpdate()', err);
		}
	};

	const handleNavigate = () => {
        dispatch(TimelineRedirectActions.resetTimelineRedirectReducers())
		navigate('/dashboard');
	}


	return (
		<>
			<Navbar />
			<TopLoadingBar />
			<TimelineView
				isLoading={isLoading}
				inView={inView}
				handleInview={handleInview}
				handleNavigate={handleNavigate}
				handleClaimCertificationStatsUpdate={handleClaimCertificationStatsUpdate}
			/>
			<Footer />
		</>
	);
}

export default Timeline;
