import React from "react";

import Tags from "@components/Tags";
import InputText from "@components/UserDashboard/Profile/InputText";
import InputCheckbox from "@components/UserDashboard/Profile/InputCheckbox";
import AlertIcon from "@assets/userDashboard/Icons/alertIcon.png";
import TickIcon from "@assets/userDashboard/Icons/tickGrey.png";
import InputError from '@assets/userDashboard/Icons/inputError.png';

function DeliverAddressView(props) {
  return (
    <>
      <div className="sTop" id="deliveryAddress">
        <div className="fHeadingContainer">
          <div className="fHeadingContainer_1 justify-content-start gap-2">
            <h3 className="fHeading">DELIVERY ADDRESS</h3>
            {props.userData.isDeliveryAddressRequired &&
              !props.userData.isAddressInfoCompleted && (
                <Tags
                  span_font_size="13px"
                  img_margin="5px"
                  bg="#FFE9E9"
                  c="#BA131A"
                  bc="#F7A8AD"
                  text={"Pending"}
                  imgs={AlertIcon}
                />
              )}
            {props.userData.isAddressInfoCompleted && (
              <Tags
                span_font_size="13px"
                img_margin="5px"
                bg="#F9F9F9"
                c="#626262"
                bc="#626262"
                text={"Submitted"}
                imgs={TickIcon}
              />
            )}
            <button
              disabled={props.savingLoading}
              className='ms-auto savingLoading'
              onClick={(e) => {
                props.handleSave();
              }}
            >
              {props.savingLoading ? "Loading..." : "Save"}
            </button>
          </div>
          <p>
            Physical delivery of certificates will be on this address. Please
            enter the details correctly,{" "}
            <b>certificates once delivered cannot be returned.</b>
          </p>
        </div>

        <div className="formContainer">
          {/* --------------- */}
          <div className="row">
            <InputText
              showError="true"
              iserror={props.iserror}
              text="Flat/House/Plot No."
              isReq="true"
              value={props.userData.flatHouseNumber}
              onChangeAction="flatHouseNumber"
              readOnly={props.userData.addressMode !== "edit"}
              {...props}
            />

            <InputText
              showError="true"
              iserror={props.iserror}
              text="Building Name"
              isReq="false"
              value={props.userData.buildingName}
              onChangeAction="buildingName"
              readOnly={props.userData.addressMode !== "edit"}
              {...props}
            />
          </div>
          {/* --------------- */}

          {/* --------------- */}
          <div className="row">
            <InputText
              showError="true"
              iserror={props.iserror}
              text="Colony Name"
              isReq="true"
              value={props.userData.colonyName}
              onChangeAction="colonyName"
              readOnly={props.userData.addressMode !== "edit"}
              {...props}
            />

            <InputText
              showError="true"
              iserror={props.iserror}
              text="Area name"
              isReq="true"
              value={props.userData.areaName}
              onChangeAction="areaName"
              readOnly={props.userData.addressMode !== "edit"}
              {...props}
            />
          </div>
          {/* --------------- */}

          {/* --------------- */}
          <div className="row">
            <InputText
              showError="true"
              iserror={props.iserror}
              text="Landmark"
              isReq="true"
              value={props.userData.landmark}
              onChangeAction="landmark"
              readOnly={props.userData.addressMode !== "edit"}
              {...props}
            />

            <div className="col-12 col-sm-6 inputContainer_x">
              <p className="inputLable d-flex align-items-center">Country <b className="asterisk">*</b>
              {props.iserror && !props.userData.country &&
                <Tags
                  span_font_size='10px'
                  img_margin='5px'
                  bg='#FFE9E9'
                  c='#962020'
                  bc='#A62323'
                  text='Input Required'
                  imgs={InputError}
                />
              }
              </p>
              <select
                value={props.userData.country}
                onChange={(event) => {
                  props.handleCountryChange(event);
                }}
                disabled={props.userData.addressMode !== "edit"}
              >
                {!props.userData.country &&
                  <option value="">Please select country</option>
                }
                {props.allCountries.map((country, index) => (
                  <option
                    key={index}
                    countrycode={country.countryCode}
                    value={country.countryName}
                  >
                    {country.countryName}
                    {/* OLD CODE : TEST AND REMOVE */}
                    {/* {props.userData.country === country.countryName
                                            ? `${country.countryCode}`
                                            : `${country.countryName}`} */}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* --------------- */}

          {/* --------------- */}
          <div className="row">
            <InputText
              showError="true"
              iserror={props.iserror}
              text="Pin Code"
              isReq="true"
              value={props.userData.pincode}
              onChangeAction="pincode"
              readOnly={props.userData.addressMode !== "edit"}
              {...props}
            />

            <div className="col-12 col-sm-6 inputContainer_x">
              <p className="inputLable d-flex align-items-center">State <b className="asterisk">*</b>
              {props.iserror && !props.userData.state &&
                <Tags
                  span_font_size='10px'
                  img_margin='5px'
                  bg='#FFE9E9'
                  c='#962020'
                  bc='#A62323'
                  text='Input Required'
                  imgs={InputError}
                />
              }
              </p>

              <select
                value={props.userData.state}
                onChange={(event) => {
                  props.handleInputChange(event, "state");
                }}
                disabled={props.userData.addressMode !== "edit"}
              >
                {!props.userData.state &&
                  <option value="">Please select state</option>
                }

                {props.allStates &&
                  props.allStates.map((state, index) => (
                    <option key={index} value={state.name}>
                      {state.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          {/* --------------- */}

          {/* --------------- */}
          <div className="row">
            <InputText
              showError="true"
              iserror={props.iserror}
              text="City"
              isReq="true"
              value={props.userData.city}
              onChangeAction="city"
              readOnly={props.userData.addressMode !== "edit"}
              {...props}
            />
          </div>
          {/* --------------- */}

          {/* --------------- */}
          <InputCheckbox
            showError="true"
            iserror={props.iserror}
            value={props.userData.addressAcknowledged}
            isReq="false"
            inputId="deliveryCheckBox"
            text="I have entered the details correctly and know certificates will be delivered to this address. I will be liable if wrong address is entered."
            readOnly={props.userData.addressMode !== "edit"}
            {...props}
          />
          {/* --------------- */}
        </div>
      </div>
    </>
  );
}

export default DeliverAddressView;
