import React from 'react';
import { useNavigate } from 'react-router-dom';

import RelatedCoursesView from './view';
import { useSelector } from 'react-redux';

function RelatedCourses() {
	const navigate = useNavigate();
	const courseDetails = useSelector((state) => state.course.courseDetails);

	const handleRedirectProduct = (seoUrlSlug) => {
		navigate(`/course/${seoUrlSlug}`);
		window.location.reload();
	};
	return (
		<RelatedCoursesView
			handleRedirectProduct={handleRedirectProduct}
			relatedCourses={courseDetails?.relatedCourses}
		/>
	);
}

export default RelatedCourses;
