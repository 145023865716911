import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { decryptText } from '@library/enc-dec';

import { getOrderDetailsByOrderId } from '@library/helpers';
import * as ExamServices from '@services/Exam';
import ETView from './view';

function Exam(props) {
	const navigate = useNavigate();

	const [etPopupPurchaseData, setETPopupPurchaseData] = useState({});
	const [openPopupStatus, setOpenPopupStatus] = useState(false);
	const closeEtPopupBtnRef = useRef(null);
	const [etPoupOkBtnLoading, setEtPoupOkBtnLoading] = useState(false)
	const examType = "Exam";

	const handleF1Exam = (purchase) => {
		setOpenPopupStatus(true);
		setETPopupPurchaseData(purchase);
	};

	const showResult = (purchase) => {
		navigate('/exam-result', {
			state: {
				purchaseId: purchase._id,
				examType : examType
			},
		});
	};

	const handleRedirectToEaxm = async (purchase) => {
		try {
			setOpenPopupStatus(false);
			if (purchase === null) {
				return;
			}

			const purchaseId = purchase._id;
			const courseId = purchase.courseId;

			if (purchase.examAttempts < 2) {
				setEtPoupOkBtnLoading(true)
				let payload = {
					purchaseId : purchaseId
				}
				const authToken = decryptText(localStorage.getItem('eMediat'));
				const response = await ExamServices.reAttemptExam(examType, authToken, payload);

				if (response.success) {
					if (closeEtPopupBtnRef.current) {
						closeEtPopupBtnRef.current.click();
					}			
					navigate('/exam', {
						state: {
							courseId: courseId,
							purchaseId: purchaseId,
						},
					});					
				}
				else{
					console.log('Issue from handleRedirectToEaxm() ', response)
					alert('Issue while re-attempting exam, re-try after refreshing the page');
				}
			} else {
				if (closeEtPopupBtnRef.current) {
					closeEtPopupBtnRef.current.click();
					const orderId = purchase.resultDetails.orderId;
					let orderDetails = await getOrderDetailsByOrderId(orderId);
					if(Object.entries(orderDetails).length > 0){
						orderDetails.data.grandTotal = "500";
						navigate('/otherPay', {
							state: {
								source:{
									expenseType : 'examReattempt'
								},
								purchaseDetails: purchase,
								orderDetails: orderDetails.data
							},
						});								
					}
					else{
						alert('Issue in getting order details, try again after refreshing the page');
					}
				}			
			}
		} catch (err) {
			console.log('Error coming from handleRedirectToEaxm()', err);
		}
	};



	return (
		<>
			<ETView
				handleF1Exam={handleF1Exam}
				etPopupPurchaseData={etPopupPurchaseData}
				openPopupStatus={openPopupStatus}
				handleRedirectToEaxm={handleRedirectToEaxm}
				showResult={showResult}
				popUpType={'Exam'}
				closeEtPopupBtnRef={closeEtPopupBtnRef}
				etPoupOkBtnLoading={etPoupOkBtnLoading}
				d={props.examDetails}
			/>
		</>
	);
}

export default Exam;
