import React from 'react';
import { Link } from 'react-router-dom';

import './style.css';

import OTP from '@components/Otp';
import HeaderLogo from '@assets/navbar/mobileHeaderLogo.png';
import CountryCodeFlagPhoneNo from '@components/CountryCodeFlagPhoneNo';

import DisplayName from '@pages/Login/Stages/DisplayName';
import Dob from '@pages/Login/Stages/Dob';
import Password from '@pages/Login/Stages/Password';

export default (props) => (
	<div className='container loginMainContainer'>
		<center>
			<img className='headerLogo' src={HeaderLogo} alt='eMediSkill' />
			<p className='header'>Login to eMediSkill Account</p>
			<p className='subHeader' id='subHeader'>
				Enter your mobile number
			</p>
		</center>

		{props.currentStage === 'phoneNo' && (
			<>
				<CountryCodeFlagPhoneNo
					handleIamIntrested={props.handleLogin}
					validationError={props.validationError}
					getterSetter={props.getterSetterPhoneNo}
				/>

				<p className='tnCContent'>
					By clicking ‘Next’ you agree to eMediSkill’s{' '}
					<Link to='/articles'>Terms and Conditions</Link> and{' '}
					<Link to='/articles'>Privacy Policy</Link>
				</p>
				<button 
					disabled={props.getOtpIsLoading}
					className='loginBtn' onClick={props.handleLogin}>					
					{props.getOtpIsLoading ? 'Loading..' : 'Next'}
				</button>
			</>
		)}

		{props.currentStage === 'otp' && (
			<div className='otpMainContainer'>
				<OTP
					handleResendOTP={props.handleResendOTP}
					handleSuccessOTP={props.handleSuccessOTP}
				/>
			</div>
		)}

		{props.currentStage === 'displayName' && (
			<DisplayName handleViewAfterLogin={props.handleSuccessLogin} />
		)}

		{props.currentStage === 'dob' && (
			<Dob handleViewAfterLogin={props.handleSuccessLogin} />
		)}

		{props.currentStage === 'password' && (
			<Password handleViewAfterLogin={props.handleSuccessLogin} />
		)}
	</div>
);
