import React, { useEffect, useState } from 'react';

// import { useSelector } from 'react-redux';

import BgLines from '@assets/course/bgLines.png';
import Bgc1 from '@assets/course/bgc1.png';
import DesktopTabView from './view';

function DesktopTab(props) {
	// const courseDetails = useSelector((state) => state.course.courseDetails);
	const [topBgOverlapPercentage, setTopBgOverlapPercentage] = useState('37%');


	useEffect(() => {
		if (!props.isLoading) {
			setTopBgOverlapHeight()
		}
		window.addEventListener('resize', handleResize);
		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [props.isLoading])


	const handleResize = () => {
		handleStyleForTopBgOverlap()
	};

	const handleStyleForTopBgOverlap = () =>{
		// return;
		let topBgOverlap = document.getElementById('topBgOverlap');
		if (topBgOverlap && props.courseDetails && props.courseDetails.cardBackgroundColor) {
			let useThisBgColor = props.courseDetails.cardBackgroundColor
			const windowWidth = window.innerWidth
			// console.log(useThisBgColor)
			// console.log(windowWidth)
			let newBgColorPercentageOfTopBgOverlap = ''
			let newBlackColorPercentageOfTopBgOverlap = ''
			// return;
			if (windowWidth >= 5000) {
				newBgColorPercentageOfTopBgOverlap = '35%'
				newBlackColorPercentageOfTopBgOverlap = '57%'	
			}
			else if (windowWidth >= 3400) {
				newBgColorPercentageOfTopBgOverlap = '30%'
				newBlackColorPercentageOfTopBgOverlap = '62%'	
			}
			else if (windowWidth >= 3000) {
				newBgColorPercentageOfTopBgOverlap = '26%'
				newBlackColorPercentageOfTopBgOverlap = '64%'	
			}
			else if (windowWidth >= 2300) {
				newBgColorPercentageOfTopBgOverlap = '31%'
				newBlackColorPercentageOfTopBgOverlap = '67%'	
			}
			else{
				newBgColorPercentageOfTopBgOverlap = '37%'
				newBlackColorPercentageOfTopBgOverlap = '71%'	
			}

			if(newBgColorPercentageOfTopBgOverlap != '' && newBlackColorPercentageOfTopBgOverlap != ''){
				topBgOverlap.style.background = `linear-gradient(90deg, rgba(0,0,0,1) ${newBgColorPercentageOfTopBgOverlap}, ${useThisBgColor} ${newBlackColorPercentageOfTopBgOverlap}, rgba(0,0,0,1) 95%)`;
			}
		}
	}

	const setTopBgOverlapHeight = () => {
		let courseHeaderContainer = document.getElementById('courseHeaderContainer');
		let topBgOverlap = document.getElementById('topBgOverlap');
		if (courseHeaderContainer && topBgOverlap) {
			let useThisBgColor = "#6f0031";
			if (props.courseDetails.cardBackgroundColor) {
				useThisBgColor = props.courseDetails.cardBackgroundColor;
			}
			// topBgOverlap.style.background = 'linear-gradient(90deg, rgba(0,0,0,1) 23%, ' + useThisBgColor + ' 48%, rgba(0,0,0,1) 70%)';
			topBgOverlap.style.background = 'linear-gradient(90deg, rgba(0,0,0,1) 37%, ' + useThisBgColor + ' 71%, rgba(0,0,0,1) 95%)';
			handleStyleForTopBgOverlap()

			let height = courseHeaderContainer.getBoundingClientRect().height + 90;

			topBgOverlap.style.height = height + 'px';
			setBgLineOverLap(height);
		}
	};

	const setBgLineOverLap = (topBgOverlapHeight) => {
		const bgLinesOverlap = document.getElementById('bgLinesOverlap');
		if (bgLinesOverlap) {
			bgLinesOverlap.style.backgroundImage = `url(${BgLines})`;
			let bgLinesOverlapTop = topBgOverlapHeight;
			bgLinesOverlap.style.top = bgLinesOverlapTop + 'px';
			let height = topBgOverlapHeight + bgLinesOverlap.getBoundingClientRect().height;
			setBgBc1Overlap(height);
		}
	}

	const setBgBc1Overlap = (bgLinesOverlapHeight) => {
		const bgBc1Overlap = document.getElementById('bgBc1Overlap');
		if (bgBc1Overlap) {
			bgBc1Overlap.style.backgroundImage = `url(${Bgc1})`;
			bgBc1Overlap.style.top = bgLinesOverlapHeight + 'px';
		}
	}


	return <DesktopTabView {...props} />;
}

export default DesktopTab;
