import React from "react";
import "./style.css";

import numeral from "numeral";
import RightArrow from "@assets/userDashboard/Icons/rightArrowForVPopup.png";

function ReviewPaymentView(props) {
  return (
    <>
      <div className="reviewPaymentView">
        <div className="courseItemContainer heading">
          <span className="name">Review Payment</span>
          <span className="price">{props.cartTotalValue}</span>
        </div>
        <hr className="mt-3 mb-3" />

        <div
          style={{ maxHeight: window.innerHeight - 350 + "px" }}
          className="midContainer"
          ref={props.midContainerRef}
        >
          {props.cartItems &&
            props.cartItems.map((course, index) => (
              <div key={index} className="courseItemContainer">
                <span className="name">{course.title}</span>
                <span className="price">
                  {course.offerPriceInNumbers > 0 && course.isInOffer
                    ? course.offerPrice
                    : course.crossedPrice}
                </span>
              </div>
            ))}

          {props.discountValueInNumbers > 0 && (
            <div className="courseItemContainer discount">
              <span className="name">Coupon Discount</span>
              <span className="price">-{props.discountValue}</span>
            </div>
          )}

          {props.gatewayDiscountValueInNumbers > 0 && (
            <div className="courseItemContainer discount">
              <span className="name">
                {props.selectedPaymentGateway.type} Discount
              </span>
              <span className="price">-{props.gatewayDiscountValue}</span>
            </div>
          )}
        </div>

        <div className="couponContainer" ref={props.couponContainerRef}>
          <input
            onChange={(event) => props.handleCouponChange(event)}
            type="text"
            placeholder="Type coupon code"
          />
          <span
            onClick={() =>
              props.couponText !== "Applied!" ? props.applyCoupon() : null
            }
          >
            {props.couponText}
          </span>
        </div>

        <button onClick={props.handleContinueToPay} className="actionBtn">
          {/* Continue to pay {props.cartTotalValue} */}
          Continue to pay  {props.actualPrice}
          <img src={RightArrow} alt="RightArrow" />
        </button>
      </div>
    </>
  );
}

export default ReviewPaymentView;
