import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { decryptText } from "@library/enc-dec";
import * as CheckoutServices from "@services/Checkout";
import TransactionsView from "./view";
import { isMobile } from 'react-device-detect';

import { renderToStaticMarkup } from "react-dom/server";
import { jsPDF } from "jspdf";
import store from "@redux/store";

import { Provider } from "react-redux";
import ReactPDF from '@react-pdf/renderer';

import { PDFViewer } from '@react-pdf/renderer';
import InvoiceMobile from '@components/InvoiceMobile';
import HdfcInvoice from '@components/Invoice/HdfcInvoice';
import StripeInvoice from '@components/Invoice/StripeInvoice';

import PdfIcon from "@assets/userDashboard/Icons/pdfIcon.png";
import LoaderGif from "@assets/course/loading.gif";

import { RobotoRegular } from "@assets/Roboto-Regular-normal.js"

function Transactions(props) {
  const navigate = useNavigate();

  const handleRedirectToInvoicePage = async (event, transactionIndex) => {
    try {
      event.target.src = LoaderGif
      let currentTransaction = props.transactionsDetails.data[transactionIndex];

      let authToken = decryptText(localStorage.getItem("eMediat"));
      const details = await CheckoutServices.getOrderById(
        currentTransaction.orderId,
        authToken
      );
      if (details.success) {
        generatePdf(event, details)

        // old code
        // if (isMobile) {
        //   generatePdf(event, details)
        // } else {
        //   navigate("/invoice", { state: { invoice: details } });
        // }
      } else {
        alert("Issue in generating invoice");
        event.target.src = PdfIcon
      }
    } catch (err) {
      console.log(err);
      event.target.src = PdfIcon
    }
  };

  const generatePdf = async (event, invoice) => {
    try {
      const doc = new jsPDF("p", "pt", "a4", true);

      doc.addFileToVFS("Roboto-Regular.ttf", RobotoRegular);
      doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
      doc.setFont("Roboto");

      // debugger
      var InvoiceComponent = null

      if (invoice.data && invoice.data.paymentMethod && invoice.data.paymentMethod) {
        // testing : razorpay stripe
        if (invoice.data.paymentMethod === "stripe") {
          InvoiceComponent = <StripeInvoice data={invoice.data} />
        } else if (invoice.data.paymentMethod === "ccavenue") {
          InvoiceComponent = <HdfcInvoice data={invoice.data} />
        }
        else {
          InvoiceComponent = <InvoiceMobile invoice={invoice} />
        }
      }
      else {
        alert("Issue in generating invoice go to dashboard and check again")
        event.target.src = PdfIcon
        return
      }

      var htmlPDFPage = renderToStaticMarkup(InvoiceComponent);

      doc.internal.write(0, "Tw");
      const pdfPromise = new Promise((resolve) => {
        doc.html(htmlPDFPage, {
          callback: function () {
            resolve(doc);
          },
        });
      });
      const generatedDoc = await pdfPromise;
      generatedDoc.save("invoice.pdf");

      setTimeout(function () {
        event.target.src = PdfIcon
      }, 100)

    } catch (err) {
      console.log("Error", err);
      event.target.src = PdfIcon
    }
  };

  return (
    <>
      <TransactionsView
        transactionsDetails={props.transactionsDetails}
        handleRedirectToInvoicePage={handleRedirectToInvoicePage}
      />
    </>
  );
}

export default Transactions;
