import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { loadStripe } from "@stripe/stripe-js";

import PayView from "./view";
import Navbar from "@components/Navbar";

import TopLoadingBar from "@components/TopLoadingBar";

import { useNavigate } from "react-router-dom";
import { decryptText, encryptText } from "@library/enc-dec";

import * as CheckoutServices from "@services/Checkout";
import * as TentativeOrdersService from "@services/TentativeOrders";
import * as TopLoadingBarActions from "@redux/actions/TopLoadingBar";
import * as PayActions from "@redux/actions/Pay";

import * as NavbarActions from "@redux/actions/Navbar";
import { isUserLogedIn } from "@library/auth";
import {
  convertObjectToQueryString,
  objectToQueryString,
} from "@library/helpers";

function Pay(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [clientSecret, setClientSecret] = useState("");
  const [stripePromise, setStripePromise] = useState(null);

  const dataFetchedRef = useRef(false);

  const userDetails = useSelector((state) => state.pay.userDetails);
  const paymentMethod = useSelector((state) => state.pay.paymentMethod);
  const orderAmount = useSelector((state) => state.pay.orderAmount);

  const orderAmountInNumbers = useSelector(
    (state) => state.pay.orderAmountInNumbers
  );
  const coursesMnemonic = useSelector((state) => state.pay.coursesMnemonic);
  const courses = useSelector((state) => state.pay.courses);
  const continent = useSelector((state) => state.pay.continent);

  const countryName = useSelector((state) => state.pay.countryName);
  const currencySymbol = useSelector((state) => state.pay.currencySymbol);
  const currencyCode = useSelector((state) => state.pay.currencyCode);

  const GST = useSelector((state) => state.pay.GST);
  const grandTotal = useSelector((state) => state.pay.grandTotal);
  const netTotal = useSelector((state) => state.pay.netTotal);
  const subTotal = useSelector((state) => state.pay.subTotal);

  const discount = useSelector((state) => state.pay.discount);
  const discountInPercentage = useSelector(
    (state) => state.pay.discountInPercentage
  );
  const discountInNumbers = useSelector((state) => state.pay.discountInNumbers);
  const offerDetails = useSelector((state) => state.pay.offerDetails);

  const gatewayDiscount = useSelector((state) => state.pay.gatewayDiscount);
  const gatewayDiscountInNumber = useSelector(
    (state) => state.pay.gatewayDiscountInNumber
  );
  const gatewayDetails = useSelector((state) => state.pay.gatewayDetails);
  const paymentStatus = useSelector((state) => state.pay.paymentStatus);
  const installmentOption = useSelector((state) => state.pay.installmentOption);

  const [isLoading, setIsLoading] = useState(false);
  const [paymentLoaderNote, setPaymentLoaderNote] = useState("");

  useEffect(() => {
    window.scroll(0, 0);
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    setPaymentLoaderNote("Payment Loading...");
    checkLogin();
    return () => {
      dispatch(PayActions.resetPayReducers());
    };
  }, []);

  const checkLogin = async () => {
    const userLogedIn = await isUserLogedIn();
    dispatch(NavbarActions.updateLoginState(userLogedIn));
    if (!userLogedIn) {
      navigate("/login", { state: { message: "Please login first" } });
      return;
    }
    // debugger
    if (paymentMethod === "stripe") {
      generateStripeOrderId();
    } else if (paymentMethod === "ccavenue") {
      generateCCAvenueOrderId();
    } else if (
      paymentMethod === "razorpay" ||
      paymentMethod === "eazypay" ||
      paymentMethod === "idfc" ||
      paymentMethod === "axis"
    ) {
      genrateRazorpayOrderId(paymentMethod);
    }
  };

  const generateStripeOrderId = async () => {
    try {
      UpdateTopLoadingBarForThisPage(30);

      let authToken = decryptText(localStorage.getItem("eMediat"));
      let finalAmount = Math.round(
        parseInt(grandTotal) - discountInNumbers - gatewayDiscountInNumber
      );

      const paymentDetails = {
        amount: parseInt(finalAmount),
        currency: currencyCode,
      };
      const orderDetails = await CheckoutServices.createOrderForStripe(
        paymentDetails,
        authToken
      );
      if (orderDetails.success) {
        if (
          orderDetails.data &&
          orderDetails.data.id &&
          orderDetails.data.client_secret
        ) {
          addTentativeOrder(orderDetails.data);
          UpdateTopLoadingBarForThisPage(50);
        } else {
          console.log("Issue while generating order id with of strip");
          navigate("/");
        }
      } else {
        alert(orderDetails.message);
        navigate("/");
        return;
      }
    } catch (err) {
      console.log("Error coming while generateStripeOrderId()", err);
      navigate("/");
    }
  };

  const genrateRazorpayOrderId = async (paymentMethod) => {
    try {
      UpdateTopLoadingBarForThisPage(30);

      let finalAmount = Math.round(
        parseInt(grandTotal) - discountInNumbers - gatewayDiscountInNumber
      );

      const paymentDetails = {
        amount: finalAmount,
        currency: currencyCode,
        paymentMethod: paymentMethod,
      };
      // debugger

      let authToken = decryptText(localStorage.getItem("eMediat"));
      const orderDetails = await CheckoutServices.createOrder(
        paymentDetails,
        authToken
      );
      if (orderDetails.success) {
        addTentativeOrder(orderDetails.data);
        UpdateTopLoadingBarForThisPage(50);
      } else {
        alert(orderDetails.message);
        navigate("/");
        return;
      }
    } catch (err) {
      console.log("Error coming while genrateRazorpayOrderId()", err);
    }
  };

  const generateCCAvenueOrderId = async () => {
    try {
      const orderId = Math.floor(Math.random() * 1000000000);

      const currencyCode = decryptText(localStorage.getItem("cc"));
      let finalAmount = Math.round(
        parseInt(grandTotal) - discountInNumbers - gatewayDiscountInNumber
      );
      const amount = finalAmount.toString() + ".00";
      const orderDetails = {
        id: orderId,
        order_id: orderId,
        amount: amount,
        currency: currencyCode,
      };

      if (orderDetails) {
        addTentativeOrder(orderDetails);
        UpdateTopLoadingBarForThisPage(50);
      } else {
        console.log("Issue while generating order id with of strip");
        navigate("/");
      }
    } catch (err) {
      console.log("Error coming while generateCCAvenueOrderId()", err);
    }
  };

  const addTentativeOrder = async (orderDetails) => {
    try {
      // debugger
      let useThisInstallmentOption = installmentOption;

      // let useThisInstallmentOption = null;
      // if (installmentOption && Object.entries(installmentOption).length > 0) {
      //   useThisInstallmentOption = {};
      //   for (const key in installmentOption) {
      //     const keyInNumber = Number(key) + 1;
      //     useThisInstallmentOption[keyInNumber] = installmentOption[key];
      //   }
      // }

      const order = {
        orderId: orderDetails.id,
        paymentId:
          paymentMethod === "stripe" || paymentMethod === "ccavenue"
            ? orderDetails.id
            : "pay_" + orderDetails.id,
        paymentMethod: paymentMethod,
        orderAmount: orderAmount,
        orderAmountInNumbers: orderAmountInNumbers,
        coursesMnemonic: coursesMnemonic,
        courses: courses,
        continent: continent,
        countryName: countryName,
        currencySymbol: currencySymbol,
        currencyCode: currencyCode,
        GST: GST,
        grandTotal: grandTotal,
        netTotal: netTotal,
        subTotal: subTotal,
        discount: discount,
        discountInNumbers: discountInNumbers,
        discountInPercentage: discountInPercentage,
        offerDetails: offerDetails,
        gatewayDiscount: gatewayDiscount,
        gatewayDiscountInNumber: gatewayDiscountInNumber,
        gatewayDetails: gatewayDetails,
        paymentStatus: paymentStatus,
        installmentOption: useThisInstallmentOption,
        installmentNumber: paymentStatus === "partial" ? 1 : 0,
        expenseType: "course",
        // expenseType: paymentStatus === "partial" ? "installment" : "course",
      };

      const authToken = decryptText(localStorage.getItem("eMediat"));
      const queryString = objectToQueryString(order);
      const encryptedPayload = encryptText(queryString);

      const response = await TentativeOrdersService.addTentativeOrder(
        { order: encryptedPayload },
        authToken
      );

      if (response.success) {
        if (paymentMethod === "stripe") {
          handleStripePayment(orderDetails);
        } else if (paymentMethod === "ccavenue") {
          handleCCAvenuePayment(orderDetails);
        } else if (
          paymentMethod === "razorpay" ||
          paymentMethod === "eazypay" ||
          paymentMethod === "idfc" ||
          paymentMethod === "axis"
        ) {
          handleRazorpayPayment(orderDetails);
        }
        return;
      }
      alert(response.message);
      navigate("/");
      return;
    } catch (err) {
      console.log("Error coming while adding tentative orders", err);
    }
  };

  const handleStripePayment = (orderDetails) => {
    try {
      setStripePromise(
        loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY)
      );
      setClientSecret(orderDetails.client_secret);
      setIsLoading(false);
      UpdateTopLoadingBarForThisPage(100);
    } catch (err) {
      console.log("Error coming while handleStripePayment()", err);
    }
  };

  const handleRazorpayPayment = (orderDetails) => {
    try {
      if (orderDetails) {
        const currencyCode = decryptText(localStorage.getItem("cc"));

        let redirectPage = "/confirm-order";
        if (window.innerWidth > 576) {
          redirectPage = "/";
        }
        var options = {
          key:
            paymentMethod === "eazypay"
              ? process.env.REACT_APP_EAZYPAY_API_KEY
              : paymentMethod === "idfc"
              ? process.env.REACT_APP_IDFC_API_KEY
              : paymentMethod === "axis"
              ? process.env.REACT_APP_AXIS_API_KEY
              : process.env.REACT_APP_RAZORPAY_API_KEY, // Enter the Key ID generated from the Dashboard
          amount: orderDetails.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: currencyCode,
          name: "eMediSkill Services LLP",
          description: "Secure Payment with eMediSkill",
          image:
            "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/rayzorpayIcon.png",
          order_id: orderDetails.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: (response) => {
            navigate(redirectPage, {
              state: {
                paymentId: response.razorpay_payment_id,
                orderId: response.razorpay_order_id,
              },
            });
          },
          prefill: {
            name: `${userDetails.firstName} ${userDetails.lastName}`,
            email: userDetails.email,
            contact: userDetails.contactNumber,
          },
          theme: {
            color: "#3399cc",
          },
          modal: {
            ondismiss: () => {},
          },
        };

        var rzp1 = new window.Razorpay(options);

        rzp1.on("payment.failed", function (response) {
          alert(response.error.description);
        });

        rzp1.open();

        UpdateTopLoadingBarForThisPage(100);
        setIsLoading(false);
      } else {
        // navigate("/");
      }
    } catch (err) {
      console.log("Error coming while handleRazorpayPayment()", err);
    }
  };

  const handleCCAvenuePayment = async (orderDetails) => {
    try {
      if (orderDetails) {
        let authToken = decryptText(localStorage.getItem("eMediat"));
        const payload = {
          tid: orderDetails.id * 2,
          order_id: orderDetails.id,
          merchant_id: process.env.REACT_APP_CCAVENUE_MERCHANT_ID,
          amount: orderDetails.amount,
          language: "EN",
          currency: orderDetails.currency,
          redirect_url: process.env.REACT_APP_CCAVENUE_REDIRECT_URL,
          cancel_url: process.env.REACT_APP_CCAVENUE_CANCEL_URL,
        };

        const queryString = convertObjectToQueryString(payload);
        const encryptedPayload = encryptText(queryString);

        const formHTML = await CheckoutServices.getCCAvenuePaymentLink(
          { data: encryptedPayload },
          authToken
        );

        if (formHTML) {
          // Create a temporary div element
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = formHTML;
          document.body.appendChild(tempDiv);
          // Submit the form
          document.redirect.submit();
        } else {
          console.error("Invalid response from server");
        }
      } else {
        navigate("/");
      }
    } catch (err) {
      console.log("Error coming while handleHdfcPayment()", err);
    }
  };

  //START: Code for setting the progressof top bar loader
  const UpdateTopLoadingBarForThisPage = (value, interval = false) => {
    if (interval) {
      setTimeout(function () {
        dispatch(
          TopLoadingBarActions.updateLoadingBarProgress(
            props.loadingBarProgress + value
          )
        );
      }, 500);
    } else {
      dispatch(
        TopLoadingBarActions.updateLoadingBarProgress(
          props.loadingBarProgress + value
        )
      );
    }
  };
  //END: Code for setting the progressof top bar loader

  return (
    <>
      <Navbar />
      <TopLoadingBar />
      <PayView
        expenseType="coursePurchase"
        stripePromise={stripePromise}
        clientSecret={clientSecret}
        paymentLoaderNote={paymentLoaderNote}
        isLoading={isLoading}
        orderAmount={orderAmount}
        paymentMethod={paymentMethod}
      />
    </>
  );
}

export default Pay;
