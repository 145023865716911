import React, { useEffect, useState } from "react";
import IdentityVerificationView from "./view";

import { useDispatch, useSelector } from "react-redux";

import { produce } from "immer";

import * as UserDashboardServices from "@services/UserDashboard";
import * as UserDashboardActions from "@redux/actions/UserDashboard";
import * as QualificationsAction from '@redux/actions/Qualifications';

import { decryptText } from "@library/enc-dec";
import { checkFileExtension, isFileUploadAllowedAsPerSize } from "@library/helpers";

function IdentityVerification(props) {
  const dispatch = useDispatch();

  const undergraduateYesNo = ["Yes", "No"];
  // const undergraduateYesNo = ["true", "false"];

  // const undergraduateDegree = ["BMS", "BMS2", "BMS3"];
  // const latestQualification = ["MBBS1", "MBBS2", "MBBS3", "MBBS4"];

  const [iserror, setIserror] = useState(false);
  const user = useSelector((state) => state.dashboard.user);
  const allQualifications = useSelector((state) => state.qualifications.allQualifications);
  const allQualificationsObj = useSelector((state) => state.qualifications.allQualificationsObj);
  const [savingLoading, setSavingLoading] = useState(false);


  useEffect(() => {
    if (!allQualifications || !allQualifications.success) {
      getAllQualifications()
    }
  }, [])


  const handleUserUpdate = (key, value) => {
    try {
      setIserror(false);
      let updatedUser = produce(user, (draft) => {
        draft[key] = value;
      });

      dispatch(UserDashboardActions.updateUserDetails(updatedUser));
    } catch (err) {
      console.log("Identity Info : Error coming from handleTextInput()", err);
    }
  };

  const getAllQualifications = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("eMediat"));
      const allQualifications = await UserDashboardServices.getAllQualifications(authToken);
      if (allQualifications.success && allQualifications.data && allQualifications.data.length > 0) {
        let values = {
          success: allQualifications.success,
          qualifications: []
        }
        dispatch(QualificationsAction.updateAllQualificationsObj(allQualifications.data))
        allQualifications.data.forEach((qualifications, index) => {
          if (qualifications.qualifications.length > 0) {
            qualifications.qualifications.forEach((q, qIndex) => {
              values.qualifications.push(q)
            })
          }
        })
        dispatch(QualificationsAction.updateAllQualifications(values))
      }
    }
    catch (err) {
      console.log("Error coming from getAllQualifications()", err);
    }
  }

  const handleInputChange = (event, inputAction) => {
    try {
      // debugger
      let eventType = event.target.type;
      let inputValue = event.target.value;
      if (inputValue === "Yes") {
        inputValue = true
      } else if (inputValue === "No") {
        inputValue = false
      }
      if (eventType === "checkbox") {
        inputValue = event.target.checked;
        handleUserUpdate("identityAcknowledged", inputValue);
      } else {
        handleUserUpdate(inputAction, inputValue);
      }
    } catch (err) {
      console.log("Identity Info : Error coming from handleInputChange()", err);
    }
  };

  const handleFileUpload = (attachId) => {
    try {
      let fileType = attachId.split("_")[0];
      document.getElementById(fileType).click();
      document
        .getElementById(fileType)
        .addEventListener("change", function (event) {
          let validFile = checkFileExtension(event);
          if (validFile) {
            let fileName = this.value.replace(/C:\\fakepath\\/i, "");
            const file = event.target.files[0];
            if (!isFileUploadAllowedAsPerSize(file)) {
              alert("File size exceeds 1 MB. Please upload a smaller file.");
              return
            }

            setIserror(false);
            let updatedUser = produce(user, (draft) => {
              draft[fileType] = file;
              if (fileType === "marksheetOrDegreeCert") {
                draft["marksheetStatus"] = "inProgress";
              } else if (fileType === "addharOrIdProof") {
                draft["addharStatus"] = "inProgress";
              } else if (fileType === "passportPhoto") {
                draft["passportPhotoStatus"] = "inProgress";
              }
            });

            dispatch(UserDashboardActions.updateUserDetails(updatedUser));
          } else {
            alert("Please upload only images");
          }
        });
    } catch (err) {
      console.log("Identity Info : Error coming from handleFileUpload()", err);
    }
  };


  const handleSave = async () => {
    try {
      const {
        isUnderGraduate,
        underGraduateDegree,
        qualification,
        marksheetOrDegreeCert,
        fullName,
        addharOrIdProof,
        passportPhoto,
        identityAcknowledged,
        marksheetStatus,
        addharStatus,
        passportPhotoStatus,
      } = user;


      let useThisQualification = null
      let profession = null
      if(isUnderGraduate){
        useThisQualification = underGraduateDegree
      }
      else{
        useThisQualification = qualification
      }

      for (let i = 0; i < allQualificationsObj.length; i++) {
        if(allQualificationsObj[i].profession && allQualificationsObj[i].qualifications && allQualificationsObj[i].qualifications.includes(useThisQualification)){
          profession = allQualificationsObj[i].profession
          break
        }
      }


      const payload = {
        isUnderGraduate,
        // underGraduateDegree: isUnderGraduate === "Yes" ? underGraduateDegree : null,
        // qualification: isUnderGraduate === "No" ? qualification : null,
        underGraduateDegree: isUnderGraduate === true ? underGraduateDegree : null,
        qualification: isUnderGraduate === false ? qualification : null,
        marksheetOrDegreeCert,
        fullName,
        addharOrIdProof,
        passportPhoto,
        identityAcknowledged,
        marksheetStatus,
        addharStatus,
        passportPhotoStatus,
        profession
      };

      

      if (
        isUnderGraduate === null || isUnderGraduate === undefined ||
        (isUnderGraduate === true && !underGraduateDegree) ||
        (isUnderGraduate === false && !qualification) ||
        !marksheetOrDegreeCert ||
        !fullName ||
        !addharOrIdProof ||
        !passportPhoto ||
        !identityAcknowledged
      ) {
        setIserror(true);
        return;
      }


      setSavingLoading(true)
      let authToken = decryptText(localStorage.getItem("eMediat"));
      const response = await UserDashboardServices.updateIdentityInfo(
        payload,
        authToken
      );

      if (response.success) {
        setSavingLoading(false)
        alert(response.message);
        setIserror(false);
        dispatch(
          UserDashboardActions.updateUserDetails({
            ...response.data,
            bssFormDetails: user.bssFormDetails,
          })
        );
        return;
      }
      setSavingLoading(false)
      alert(response.message);
    } catch (err) {
      console.log("Identity Info : Error coming from handleSave()", err);
    }
  };

  return (
    <>
      {user && (
        <IdentityVerificationView
          iserror={iserror}
          userData={user}
          handleInputChange={handleInputChange}
          // undergraduateDegree={undergraduateDegree}
          // latestQualification={latestQualification}
          allQualifications={allQualifications && allQualifications.qualifications ? allQualifications.qualifications : []}
          undergraduateYesNo={undergraduateYesNo}
          handleFileUpload={handleFileUpload}
          handleSave={handleSave}
          savingLoading={savingLoading}
        />
      )}
    </>
  );
}

export default IdentityVerification;
