import React from "react";
import { Link } from "react-router-dom";

import WriteToUs from "@components/Popups/CoursePagePopupsContent/WriteToUs";
import Cart from "@components/Popups/CoursePagePopupsContent/Cart";
import Offer from "@components/Popups/CoursePagePopupsContent/Offer";
import Installment from "@components/Popups/CoursePagePopupsContent/Installment";
import PaymentGateway from "@components/Popups/CoursePagePopupsContent/PaymentGateway";
import UserDetail from "@components/Popups/CoursePagePopupsContent/UserDetail";
import ReviewPayment from "@components/Popups/CoursePagePopupsContent/ReviewPayment";
import NameAndPhoneNoOtpContent from "@components/Popups/NameAndPhoneNoOtpContent";

import "./style.css";
import Clock from "@assets/product/clock.png";
import TopGrayArrow from "@assets/course/topGrayArrow.png";
import RightWhiteArrow from "@assets/userDashboard/Icons/rightArrowForVPopup.png";
import BlackCross from "@assets/course/blackCross.png";

function RestOfPopupsOfCoursePageView(props) {
  return (
    <>
      {props.currentPage === "course" || props.cartItems ? (
        <div className="restOfPopupsOfCoursePageView">
          <div
            onClick={() => {
              props.handleOpenCloseCartForDesktop();
            }}
            className="cartBackground"
            ref={props.cartBackgroundRef}
          ></div>
          {window.innerWidth > 768 ? (
            // START : ---------- Desktop view
            <div className="mainDesktopCartConatiner">
              {/* {props.cartItems && props.cartItems.length > 0 && (
								<> */}
              {/* START : Sticky fixedCartNote for desktop and non course page */}
              {props.cartItems.length > 0 && (
                <div className="fixedCartNote" ref={props.fixedCartNoteRef}>
                  <span>
                    <b>
                      {props.cartItems.length} item
                      {props.cartItems.length > 1 && "(s)"}{" "}
                    </b>{" "}
                    added in cart
                  </span>
                  <span
                    onClick={() => {
                      props.handleOpenCloseCartForDesktop();
                    }}
                    className="viewCartText"
                  >
                    View cart
                  </span>
                </div>
              )}
              {/* END : Sticky fixedCartNote for desktop and non course page */}

              <div
                style={{ width: `${props.cartContainerDesktopWidth}px` }}
                className="cartContainerDesktop"
                ref={props.cartContainerDesktopRef}
              >
                <div className="headerContainer">
                  <span className="heading">Your Cart</span>
                  <img
                    onClick={() => {
                      props.handleOpenCloseCartForDesktop();
                    }}
                    className="blackCross"
                    src={BlackCross}
                    alt="blackCross"
                  />
                </div>
                <div className="subHeadingContainer">
                  <span
                    className={`subHeading ${
                      props.popupViewX === "cart" ||
                      props.popupViewX === "reviewPayment"
                        ? ""
                        : "d-none"
                    }`}
                  >
                    PRODUCTS
                  </span>
                  <hr />
                </div>
                <div className="cartContainerDesktoptInternalView">
                  {props.popupViewX === "cart" ? (
                    <Cart device="desktop" {...props} />
                  ) : props.popupViewX === "offers" ? (
                    <Offer {...props} />
                  ) : props.popupViewX === "installments" ? (
                    <Installment showCallComponenet={true} {...props} />
                  ) : props.popupViewX === "userDetails" ? (
                    <UserDetail {...props} />
                  ) : props.popupViewX === "paymentGateway" ? (
                    <PaymentGateway {...props} />
                  ) : props.popupViewX === "reviewPayment" ? (
                    <ReviewPayment {...props} />
                  ) : null}
                </div>
              </div>
              {/* </>
							)} */}
            </div>
          ) : (
            // END : ---------- Desktop view

            // START : ---------- Mobile view
            <div
              ref={props.stickyCartCotainerRef}
              className="fixed-bottom stickyCartCotainer"
            >
              <div
                ref={props.GTstickyCartCotainerRef}
                className="topGrayArrowContainer"
              >
                <img src={TopGrayArrow} alr="TopGrayArrow" />
              </div>

              {props.currentPage === "course" &&
                props.isValidDealEndingTime && (
                  <p
                    ref={props.dealEndingContainerRef}
                    className="dealEndingContainer"
                  >
                    <img src={Clock} alt="clock" />
                    Offer price ending in&nbsp;<b>{props.dealEndingTime}</b>
                  </p>
                )}

              {props.currentPage === "course" &&
                props.courseData &&
                !props.courseData.isPurchasedCourse &&
                props.courseData?.pricingDetails?.courseType === "paid" && (
                  <div ref={props.priceContainerRef} className="priceContainer">
                    <p className="alwaysDisplayPrice">
                      {props.courseData?.pricingDetails?.offerExpiry &&
                      props.courseData?.isInOffer
                        ? props.courseData.offerPrice
                        : props.courseData.crossedPrice}
                    </p>

                    {props.courseData?.isInOffer && (
                      <p className="crossPrice">
                        <del>{props.courseData?.crossedPrice}</del>
                      </p>
                    )}
                    {props.courseData?.isInOffer && (
                      <p className="discountedPercentage">
                        {props.calculateOfferPercentage()}
                      </p>
                    )}
                  </div>
                )}

              <div
                ref={props.stickyCartInternalViewRef}
                className="stickyCartInternalView"
              >
                {props.popupViewX === "registration" ? (
                  <NameAndPhoneNoOtpContent
                    handleSuccessOtp={props.interhandleSuccessOtp2}
                  />
                ) : props.popupViewX === "writeToUs" ? (
                  <WriteToUs
                    fullNameSetter={props.fullNameSetter} // ref : fn defined in pages\Course\Main\index.js
                    handleSuccessOtp={props.interhandleSuccessOtp}
                  />
                ) : props.popupViewX === "writeToUs2" ? ( // This is kept to re-render same component after otp success
                  <>
                    <h1 style={{ display: "none" }}>d</h1>
                    <WriteToUs
                      fullNameSetter={props.fullNameSetter} // ref : fn defined in pages\Course\Main\index.js
                      handleSuccessOtp={props.interhandleSuccessOtp}
                    />
                  </>
                ) : props.popupViewX === "cart" ? (
                  <Cart {...props} />
                ) : props.popupViewX === "offers" ? (
                  <Offer {...props} />
                ) : props.popupViewX === "installments" ? (
                  <Installment {...props} />
                ) : props.popupViewX === "userDetails" ? (
                  <UserDetail {...props} />
                ) : props.popupViewX === "paymentGateway" ? (
                  <PaymentGateway {...props} />
                ) : props.popupViewX === "reviewPayment" ? (
                  <ReviewPayment {...props} />
                ) : null}
              </div>
              {props.currentPage === "course" ? (
                // This is mobile sticky view for course pages
                <>
                  {props.courseData?.pricingDetails?.courseType === "paid" && (
                    <div
                      ref={props.actionBtnContainerRef}
                      className="btnContainer"
                    >
                      {!props.courseData.isPurchasedCourse && (
                        <button
                          disabled={
                            props.addToCartLoading &&
                            props.addToCartLoading === props.courseData._id
                              ? true
                              : false
                          }
                          onClick={() => props.addToCartOrEnroll("enroll")}
                          className={`enrollNowBtn ${
                            props.courseData.allowToCart ? "col-my5" : "col-12"
                          }`}
                        >
                          {props.addToCartLoading &&
                          props.addToCartLoading === props.courseData._id
                            ? "Loading..."
                            : "Enroll now"}
                        </button>
                      )}

                      {props.courseData.allowToCart && (
                        <>
                          {!props.courseData.isPurchasedCourse ? (
                            <button
                              disabled={
                                props.addToCartLoading &&
                                props.addToCartLoading === props.courseData._id
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                props.addToCartOrEnroll("addOrViewCart")
                              }
                              className={`${
                                props.courseData.isPurchasedCourse
                                  ? "col-12 enrollNowBtn"
                                  : "col-my5 cartActionBtn"
                              }`}
                            >
                              {props.addToCartLoading &&
                              props.addToCartLoading ===
                                props.courseData._id ? (
                                "Loading..."
                              ) : (
                                <>
                                  {props.cartItems.some(
                                    (item) =>
                                      item.courseId === props.courseData._id
                                  )
                                    ? "View cart"
                                    : "Add to cart"}
                                </>
                              )}
                            </button>
                          ) : (
                            <Link
                              style={{
                                textAlign: "center",
                                textDecoration: "none",
                              }}
                              className="col-12 enrollNowBtn"
                              to={`/timeline/${props.courseData.purchaseId}`}
                            >
                              Go to Course
                            </Link>
                          )}
                        </>
                      )}
                    </div>
                  )}

                  {props.courseData?.pricingDetails?.courseType ===
                    "comingSoon" && (
                    <div className="btnContainer">
                      <button
                        onClick={() => {
                          props.handleContactNow();
                        }}
                        className="col-12 enrollNowBtn"
                      >
                        Coming Soon
                      </button>
                    </div>
                  )}

                  {props.courseData?.pricingDetails?.courseType === "free" && (
                    <div className="btnContainer">
                      {!props.courseData.isPurchasedCourse ? (
                        <button
                          disabled={
                            props.addToCartLoading &&
                            props.addToCartLoading === "addingToMyLearning"
                              ? true
                              : false
                          }
                          style={{
                            background:
                              props.addToCartLoading === "addingToMyLearning"
                                ? "#7fce7d"
                                : "#039800",
                            color: "white",
                          }}
                          onClick={() => props.handleFreeCourseIntermediate()}
                          className="col-12 enrollNowBtn"
                        >
                          {props.addToCartLoading &&
                          props.addToCartLoading === "addingToMyLearning"
                            ? "Loading..."
                            : "Add to my learning"}
                        </button>
                      ) : (
                        <Link
                          style={{
                            textAlign: "center",
                            textDecoration: "none",
                          }}
                          className="col-12 enrollNowBtn"
                          to={`/timeline/${props.courseData.purchaseId}`}
                        >
                          Go to Course
                        </Link>
                      )}
                    </div>
                  )}
                </>
              ) : (
                props.cartItems &&
                props.cartItems.length > 0 && ( // This is mobile sticky view for non course pages
                  <div
                    ref={props.actionBtnContainerOfToLevelRef}
                    className="actionBtnContainerOfToLevel"
                  >
                    <p ref={props.cartItemText} className="cartItemText">
                      <b>
                        {props.cartItems.length} item
                        {props.cartItems.length > 1 && "(s)"}
                      </b>{" "}
                      added to cart
                    </p>
                    <button
                      ref={props.mobileContinueToPayBtn}
                      onClick={() => props.addToCartOrEnroll("addOrViewCart")}
                      className="actionBtn"
                    >
                      Continue to pay {props.totalCartValue}
                      <img src={RightWhiteArrow} alt="RightWhiteArrow" />
                    </button>
                  </div>
                )
              )}
            </div>
            // END : ---------- Mobile view
          )}
        </div>
      ) : (
        // Just kept to map event listeners for handle touch
        <div
          ref={props.GTstickyCartCotainerRef}
          className="topGrayArrowContainer"
        ></div>
      )}
    </>
  );
}

export default RestOfPopupsOfCoursePageView;
