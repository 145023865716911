import { useEffect, useState } from "react";
import { Provider } from "react-redux";

import Login from "@pages/Login/Main";

import SignUp from "@pages/SignUp";
import Home from "@pages/Home";
import T from "@pages/Home/t.js";
import AboutUs from "@pages/AboutUs";
import RequestACall from "@pages/RequestACall";
import ForgotPassword from "@pages/ForgotPassword";
import Explore from "@pages/Explore/Main";
import ExploreCourseCategory from "@pages/Explore/ExploreCourseCategory";

import Cart from "@pages/Cart";
import Course from "@pages/Course/Main";
import Checkout from "@pages/Checkout";
import ConfirmOrder from "@pages/ConfirmOrder";
import TermsAndConditions from "@pages/TermsAndConditions";
import Offers from "@pages/Offers";
import Timeline from "@pages/UserDashboard/MyLearning/Timeline/Main";

import UserDashboard from "@pages/UserDashboard/Main";
import VerifyEamil from "./pages/VerifyEmail";
import Exam from "./pages/Exam";
import ExamSubmitted from "./pages/ExamSubmitted";
import ExamResult from "./pages/ExamResult";
import Pay from "./pages/Pay";
import CCAvenueResponse from "./pages/CCAvenueResponse";
import OtherPay from "./pages/Pay/OtherPay";

import InvoicePDF from "./pages/Invoice";

import CourseDetails from "@pages/CourseDetails";

import SeoHandler from "@pages/SeoHandler";
import TestData from "@pages/testData";
import { getUserLocation } from "./library/helpers/index.js";

import "./App.css";
import store from "@redux/store";

import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  useEffect(() => {
    getUserLocation();
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        {/* <Navbar /> */}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/t" element={<T />} />
          <Route path="/signup" element={<Login />} />
          <Route path="/forget-password" element={<ForgotPassword />} />
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/request-a-call" element={<RequestACall />} />
          <Route path="/explore" element={<Explore />} />
          <Route
            path="/exploreCourseCategory/:id"
            element={<ExploreCourseCategory />}
          />
          <Route path="/cart" element={<Cart />} />
          <Route path="/course/:id" element={<Course />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/confirm-order" element={<ConfirmOrder />} />
          <Route path="/articles/:pathUrl?" element={<TermsAndConditions />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/dashboard" element={<UserDashboard />} />
          <Route path="/verifyEmail" element={<VerifyEamil />} />
          <Route path="/exam" element={<Exam />} />
          {/* <Route path='/exam-submitted' element={<ExamSubmitted />} /> */}
          <Route path="/exam-result" element={<ExamResult />} />
          <Route path="/pay" element={<Pay />} />
          <Route path="/CCAvenueResponse" element={<CCAvenueResponse />} />
          <Route path="/invoice" element={<InvoicePDF />} />
          <Route path="/otherPay" element={<OtherPay />} />
          {/* <Route path='/video/:identifier/:id/:t?' element={<CourseDetails />} /> */}
          <Route
            path="/video/:identifier/:id/:purchaseId"
            element={<CourseDetails />}
          />
          <Route path="/video/:identifier/:id" element={<CourseDetails />} />
          <Route path="/html/:identifier?" element={<SeoHandler />} />
          <Route path="/testData" element={<TestData />} />
          <Route path="/timeline/:id" element={<Timeline />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
